import { RouteRecordRaw } from "vue-router";
import { addRoutes } from "@/core/router";
import { registerNavMenuItem, NavMenuItem } from "@/core/layout";

const configurationsRoute: RouteRecordRaw = {
  path: "/configurations",
  name: "configurations",
  component: () => import("@/configuration/pages/Configurations.vue"),
  meta: {
    authorize: ["CONFIGURATIONS.LIST.VIEW"],
    breadCrumbs: [
      {
        name: "Home",
        route: "/dashboard",
        isLast: false,
      },
      {
        name: "Configurations",
        route: "",
        isLast: false,
      },
    ],
  },
};

const creteConfigurationsRoute: RouteRecordRaw = {
  path: "/configurations/create",
  name: "createConfigurations",
  component: () => import("@/configuration/pages/EditConfigurations.vue"),
  meta: {
    authorize: ["CONFIGURATIONS.LIST.CREATE"],
    breadCrumbs: [
      {
        name: "Home",
        route: "/dashboard",
        isLast: false,
      },
      {
        name: "Configurations",
        route: "/configurations",
        isLast: false,
      },
      {
        name: "Create",
        route: "",
        isLast: true,
      },
    ],
  },
};

const editConfigurationsRoute: RouteRecordRaw = {
  path: "/configurations/edit/:id",
  name: "editConfigurations",
  component: () => import("@/configuration/pages/EditConfigurations.vue"),
  meta: {
    authorize: ["CONFIGURATIONS.LIST.EDIT"],
    breadCrumbs: [
      {
        name: "Home",
        route: "/dashboard",
        isLast: false,
      },
      {
        name: "Configurations",
        route: "/configurations",
        isLast: false,
      },
      {
        name: "Edit",
        route: "",
        isLast: true,
      },
    ],
  },
};
// commenting configuration route so that user can't hit it directly on the url, Oneteg Ui will not contain configuration menu.
// addRoutes([configurationsRoute, creteConfigurationsRoute, editConfigurationsRoute]);

const configurationItem: NavMenuItem = {
  iconName: "sliders-h",
  label: "Configurations",
  name: "configurations",
  targetRoute: {
    path: "/configurations",
    name: "configurations",
    component: () => import("@/configuration/pages/Configurations.vue"),
  },
  authority: "CONFIGURATIONS.LIST.VIEW",
  rank: 30,
  isParent: false,
  hasParent: false,
};
// commenting register function. Oneteg Ui will not contain configuration menu.
// registerNavMenuItem(configurationItem);
