import { getEnv } from "@/core/util";

export function getLoaderUrl(url: string): string {
  const images = require.context("./assets/", false, /\.png$/);
  return images(`./${url}.png`);
}

export const ASSETS_BASE_URL = getEnv("CDN_URL");
export const DEFAULT_LOGO = "oneteg-default";

export function getDefaultLogo(): string {
  const images = require.context("./assets/", false, /\.png$/);
  return images("./oneteg-default.png");
}

export function getResolution(label: boolean): string {
  const {
    height,
    width,
  } = window.screen;
  if (height <= 1050 && width <= 1680) {
    return label ? "-f-f-f" : "-s-s-s";
  }
  if (between(height, 1051, 1439) && between(width, 1681, 2559)) {
    return label ? "-f-f" : "-s-s";
  }
  if (height >= 1440 && width >= 2560) {
    return label ? "-f" : "-s";
  }
  return label ? "-f-f" : "-s-s";
}

function between(number: number, min: number, max: number) {
  return number >= min && number <= max;
}
