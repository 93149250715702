import { RouteRecordRaw } from "vue-router";
import { NavMenuItem, registerNavMenuItem } from "@/core/layout";
import { addRoutes } from "@/core/router";

const tenantsRoute: RouteRecordRaw = {
  path: "/tenants",
  name: "tenants",
  component: () => import("@/tenant/pages/Tenants.vue"),
  meta: {
    authorize: ["TENANTS.LIST.VIEW"],
    breadCrumbs: [
      {
        name: "Home",
        route: "/dashboard",
        isLast: false,
      },
      {
        name: "Tenants",
        route: "",
        isLast: true,
      },
    ],
  },
};

const createTenantsRoute: RouteRecordRaw = {
  path: "/tenants/create",
  name: "createTenants",
  component: () => import("@/tenant/pages/EditTenant.vue"),
  meta: {
    authorize: ["TENANTS.LIST.CREATE"],
    breadCrumbs: [
      {
        name: "Home",
        route: "/dashboard",
        isLast: false,
      },
      {
        name: "Tenants",
        route: "/tenants",
        isLast: false,
      },
      {
        name: "Create",
        route: "",
        isLast: true,
      },
    ],
  },
};

const editTenantsRoute: RouteRecordRaw = {
  path: "/tenants/edit/:id",
  name: "editTenants",
  component: () => import("@/tenant/pages/EditTenant.vue"),
  meta: {
    authorize: ["TENANTS.LIST.EDIT"],
    breadCrumbs: [
      {
        name: "Home",
        route: "/dashboard",
        isLast: false,
      },
      {
        name: "Tenants",
        route: "/tenants",
        isLast: false,
      },
      {
        name: "Edit",
        route: "",
        isLast: true,
      },
    ],
  },
};

const tenantDetailsRoute: RouteRecordRaw = {
  path: "/tenants/details/:id",
  name: "tenantDetails",
  component: () => import("@/tenant/pages/TenantDetails.vue"),
  meta: {
    authorize: ["TENANTS.LIST.VIEW_DETAIL"],
    breadCrumbs: [
      {
        name: "Home",
        route: "/dashboard",
        isLast: false,
      },
      {
        name: "Tenants",
        route: "/tenants",
        isLast: false,
      },
      {
        name: "Details",
        route: "",
        isLast: true,
      },
    ],
  },
};
// commenting tenant route so that user can't hit it directly on the url, Oneteg Ui will not contain tenant menu.
// addRoutes([tenantsRoute, createTenantsRoute, editTenantsRoute, tenantDetailsRoute]);

const tenantItem: NavMenuItem = {
  iconName: "building",
  label: "Tenants",
  name: "tenants",
  targetRoute: {
    path: "/tenants",
    name: "tenants",
    component: () => import("@/tenant/pages/Tenants.vue"),
  },
  authority: "TENANTS.LIST.VIEW",
  rank: 50,
  isParent: false,
  hasParent: false,
};

// commenting register function. Oneteg Ui will not contain tenants menu.
// registerNavMenuItem(tenantItem);
