export default {
  mounted(el, binding) {
    el.ClickOutsideHandler = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        if (binding.value.disabledScopeElements.some((elementName) => [...document.querySelectorAll(elementName)].some((element) => element.contains(event?.target)))) return;
        binding.value.handle(event);
      }
    };
    document.body.addEventListener("click", el.ClickOutsideHandler);
  },
  beforeUnmount(el) {
    document.body.removeEventListener("click", el.ClickOutsideHandler);
  },
};
