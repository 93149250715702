import { RouteRecordRaw } from "vue-router";
import { addRoutes } from "@/core/router";
import { addBreadCrumbs, BreadcrumbParam } from "@/core/layout";

const userPreferencesRoute: RouteRecordRaw = {
  path: "/user-preferences",
  name: "userPreferences",
  component: () => import("@/user-preferences/pages/UserPreferences.vue"),
  meta: {},
};

addRoutes([userPreferencesRoute]);

const userPreferencesBreadcrumb: BreadcrumbParam = {
  routeName: "userPreferences",
  items: [
    {
      labelKey: "Auth.Home",
      route: "/dashboard",
      disabled: false,
      isTranslated: true,
      uniqueLabel: "",
    },
    {
      labelKey: "Preferences.Preferences",
      route: "/user-preferences",
      disabled: true,
      isTranslated: true,
      uniqueLabel: "",
    },
  ],
};

addBreadCrumbs([userPreferencesBreadcrumb]);
