import { RouteRecordRaw } from "vue-router";
import { addRoutes } from "@/core/router";
import {
  registerNavMenuItem, NavMenuItem, addBreadCrumbs, BreadcrumbParam,
} from "@/core/layout";

const usersRoute: RouteRecordRaw = {
  path: "/users",
  name: "users",
  component: () => import("@/user/pages/Users.vue"),
  meta: {
    authorize: ["USERS.LIST.VIEW"],
  },
};
const createUsersRoute: RouteRecordRaw = {
  path: "/users/create",
  name: "createUsers",
  component: () => import("@/user/pages/EditUser.vue"),
  meta: {
    authorize: ["USERS.LIST.CREATE"],
  },
};
const editUsersRoute: RouteRecordRaw = {
  path: "/users/edit/:id",
  name: "editUsers",
  component: () => import("@/user/pages/EditUser.vue"),
  meta: {
    authorize: ["USERS.LIST.EDIT"],
  },
};
const detailUsersRoute: RouteRecordRaw = {
  path: "/users/detail/:id",
  name: "detailUsers",
  component: () => import("@/user/pages/UserDetail.vue"),
  meta: {
    authorize: ["USERS.LIST.VIEW_DETAIL"],
  },
};
addRoutes([usersRoute, createUsersRoute, editUsersRoute, detailUsersRoute]);

const userItem: NavMenuItem = {
  iconName: "user",
  label: "Users",
  name: "users",
  targetRoute: {
    path: "/users",
    name: "users",
    component: () => import("@/user/pages/Users.vue"),
  },
  authority: "USERS.LIST.VIEW",
  rank: 50,
  isParent: false,
  hasParent: false,
};
registerNavMenuItem(userItem);

const usersBreadcrumb: BreadcrumbParam = {
  routeName: "users",
  items: [
    {
      labelKey: "Auth.Home",
      route: "/dashboard",
      disabled: false,
      isTranslated: true,
      uniqueLabel: "",
    },
    {
      labelKey: "Common.Users",
      route: "/users",
      disabled: true,
      isTranslated: true,
      uniqueLabel: "",
    },
  ],
};

const createUserBreadcrumb: BreadcrumbParam = {
  routeName: "createUsers",
  items: [
    {
      labelKey: "Auth.Home",
      route: "/dashboard",
      disabled: false,
      isTranslated: true,
      uniqueLabel: "",
    },
    {
      labelKey: "Common.Users",
      route: "/users",
      disabled: false,
      isTranslated: true,
      uniqueLabel: "",
    },
    {
      labelKey: "Common.Create",
      route: "",
      disabled: true,
      isTranslated: true,
      uniqueLabel: "",
    },
  ],
};

const editUserBreadcrumb: BreadcrumbParam = {
  routeName: "editUsers",
  items: [
    {
      labelKey: "Auth.Home",
      route: "/dashboard",
      disabled: false,
      isTranslated: true,
      uniqueLabel: "",
    },
    {
      labelKey: "Common.Users",
      route: "/users",
      disabled: false,
      isTranslated: true,
      uniqueLabel: "",
    },
    {
      labelKey: "Common.Edit",
      route: "",
      disabled: true,
      isTranslated: true,
      uniqueLabel: "",
    },
  ],
};

const userDetailsBreadcrumb: BreadcrumbParam = {
  routeName: "detailUsers",
  items: [
    {
      labelKey: "Auth.Home",
      route: "/dashboard",
      disabled: false,
      isTranslated: true,
      uniqueLabel: "",
    },
    {
      labelKey: "Common.Users",
      route: "/users",
      disabled: false,
      isTranslated: true,
      uniqueLabel: "",
    },
    {
      labelKey: "Common.Details",
      route: "",
      disabled: true,
      isTranslated: true,
      uniqueLabel: "",
    },
  ],
};

addBreadCrumbs([usersBreadcrumb, createUserBreadcrumb, editUserBreadcrumb, userDetailsBreadcrumb]);
