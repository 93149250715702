import { RouteRecordRaw } from "vue-router";
import {
  NavMenuItem, registerNavMenuItem, addBreadCrumbs, BreadcrumbParam,
} from "@/core/layout";
import { addRoutes } from "@/core/router";

const organizationsRoute: RouteRecordRaw = {
  path: "/organizations",
  name: "organizations",
  component: () => import("@/organization/pages/Organizations.vue"),
  meta: {
    authorize: ["ORGANIZATIONS.LIST.VIEW"],
  },
};

const createOrganizationsRoute: RouteRecordRaw = {
  path: "/organizations/create",
  name: "createOrganizations",
  component: () => import("@/organization/pages/EditOrganization.vue"),
  meta: {
    authorize: ["ORGANIZATIONS.LIST.CREATE"],
  },
};

const editOrganizationsRoute: RouteRecordRaw = {
  path: "/organizations/edit/:id",
  name: "editOrganizations",
  component: () => import("@/organization/pages/EditOrganization.vue"),
  meta: {
    authorize: ["ORGANIZATIONS.LIST.EDIT"],
  },
};

const organizationDetailsRoute: RouteRecordRaw = {
  path: "/organizations/details/:id",
  name: "organizationDetails",
  component: () => import("@/organization/pages/OrganizationDetails.vue"),
  meta: {
    authorize: ["ORGANIZATIONS.LIST.VIEW_DETAIL"],
  },
};

addRoutes([organizationsRoute, createOrganizationsRoute, editOrganizationsRoute, organizationDetailsRoute]);

const organizationItem: NavMenuItem = {
  iconName: "building",
  label: "Organizations",
  name: "organizations",
  targetRoute: {
    path: "/organizations",
    name: "organizations",
    component: () => import("@/organization/pages/Organizations.vue"),
  },
  authority: "ORGANIZATIONS.LIST.VIEW",
  rank: 45,
  isParent: false,
  hasParent: false,
};

registerNavMenuItem(organizationItem);

const organizationsBreadcrumb: BreadcrumbParam = {
  routeName: "organizations",
  items: [
    {
      labelKey: "Auth.Home",
      route: "/dashboard",
      disabled: false,
      isTranslated: true,
      uniqueLabel: "",
    },
    {
      labelKey: "Common.Organizations",
      route: "/organizations",
      disabled: true,
      isTranslated: true,
      uniqueLabel: "",
    },
  ],
};

const createOrganizationBreadcrumb: BreadcrumbParam = {
  routeName: "createOrganizations",
  items: [
    {
      labelKey: "Auth.Home",
      route: "/dashboard",
      disabled: false,
      isTranslated: true,
      uniqueLabel: "",
    },
    {
      labelKey: "Common.Organizations",
      route: "/organizations",
      disabled: false,
      isTranslated: true,
      uniqueLabel: "",
    },
    {
      labelKey: "Common.Create",
      route: "",
      disabled: true,
      isTranslated: true,
      uniqueLabel: "",
    },
  ],
};

const editOrganizationBreadcrumb: BreadcrumbParam = {
  routeName: "editOrganizations",
  items: [
    {
      labelKey: "Auth.Home",
      route: "/dashboard",
      disabled: false,
      isTranslated: true,
      uniqueLabel: "",
    },
    {
      labelKey: "Common.Organizations",
      route: "/organizations",
      disabled: false,
      isTranslated: true,
      uniqueLabel: "",
    },
    {
      labelKey: "Common.Edit",
      route: "",
      disabled: true,
      isTranslated: true,
      uniqueLabel: "",
    },
  ],
};

const organizationDetailsBreadcrumb: BreadcrumbParam = {
  routeName: "organizationDetails",
  items: [
    {
      labelKey: "Auth.Home",
      route: "/dashboard",
      disabled: false,
      isTranslated: true,
      uniqueLabel: "",
    },
    {
      labelKey: "Common.Organizations",
      route: "/organizations",
      disabled: false,
      isTranslated: true,
      uniqueLabel: "",
    },
    {
      labelKey: "Common.Details",
      route: "",
      disabled: true,
      isTranslated: true,
      uniqueLabel: "",
    },
  ],
};

addBreadCrumbs([organizationsBreadcrumb,
  createOrganizationBreadcrumb,
  editOrganizationBreadcrumb,
  organizationDetailsBreadcrumb]);
