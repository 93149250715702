import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faBuilding,
  faCalendar,
  faClock,
  faCog,
  faShareSquare,
  faEdit,
  faExpand,
  faFileExcel,
  faGlobe,
  faHistory,
  faHome,
  faLock,
  faNetworkWired,
  faPlay,
  faPlug,
  faPlus,
  faRedoAlt,
  faSignInAlt,
  faSignOutAlt,
  faStop,
  faTachometerAlt,
  faTimes,
  faTrash,
  faUndo,
  faUser,
  faUserPlus,
  faLink,
  faCheck,
  faTh,
  faAlignJustify,
  faClone,
  faWindowRestore,
  faWindowMaximize,
  faMinus,
  faArrowLeft,
  faArrowRight,
  faEye,
  faSlidersH,
  faIndent,
  faShieldAlt,
  faServer,
  faEnvelope,
  faFilter,
  faExchangeAlt,
  faDatabase,
  faPencilAlt,
  faInfoCircle,
  faCircle,
  faCompress,
  faCompressAlt,
  faLayerGroup,
  faRedo,
  faPause,
  faEllipsisH,
  faBolt,
  faCodeBranch,
  faInfo,
  faBusinessTime,
  faSave,
  faEject,
  faStepForward,
  faHourglassStart,
  faCogs,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";

library.add(faHome, faUser, faLock, faGlobe, faUserPlus, faSignInAlt, faSignOutAlt, faFileExcel,
  faTachometerAlt, faNetworkWired, faHistory, faPlug, faBuilding, faExpand, faPlay, faStop,
  faRedoAlt, faPlus, faTimes, faTrash, faEdit, faCalendar, faUndo, faClock, faCog, faShareSquare, faLink, faCheck, faTh,
  faAlignJustify, faClone, faWindowRestore, faWindowMaximize, faMinus, faArrowLeft, faArrowRight, faEye, faSlidersH,
  faIndent, faEnvelope, faDatabase, faFilter, faExchangeAlt, faEnvelope, faShieldAlt, faServer, faPencilAlt,
  faInfoCircle, faCircle, faCompress, faCompressAlt, faLayerGroup, faRedo, faPause, faEllipsisH, faBolt,
  faBusinessTime, faInfo, faCodeBranch, faSave, faEject, faStepForward, faHourglassStart, faCogs, faDollarSign);

export { FontAwesomeIcon };
