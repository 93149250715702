import { RouteRecordRaw } from "vue-router";
import {
  NavMenuItem, registerNavMenuItem, BreadcrumbParam, addBreadCrumbs,
} from "@/core/layout";
import { addRoutes } from "@/core/router";

const connectorsRoute: RouteRecordRaw = {
  path: "/connectors",
  name: "connectors",
  component: () => import("@/connector/pages/Connectors.vue"),
  meta: {},
};

const createConnectorRoute:RouteRecordRaw = {
  path: "/connectors/create",
  name: "createConnectors",
  component: () => import("@/connector/pages/EditConnector.vue"),
  meta: {
    authorize: ["CONNECTORS.LIST.CREATE"],
    breadCrumbs: [
      {
        name: "Home",
        route: "/dashboard",
        isLast: false,
      },
      {
        name: "Connectors",
        route: "/connectors",
        isLast: false,
      },
      {
        name: "Create",
        route: "",
        isLast: true,
      },
    ],
  },
};

const editConnectorRoute:RouteRecordRaw = {
  path: "/connectors/edit/:id",
  name: "editConnectors",
  component: () => import("@/connector/pages/EditConnector.vue"),
  meta: {
    authorize: ["CONNECTORS.LIST.EDIT"],
    breadCrumbs: [
      {
        name: "Home",
        route: "/dashboard",
        isLast: false,
      },
      {
        name: "Connectors",
        route: "/connectors",
        isLast: false,
      },
      {
        name: "Edit",
        route: "",
        isLast: true,
      },
    ],
  },
};
// commenting connector route so that user can't hit it directly on the url, Oneteg Ui will not contain connector menu.
addRoutes([connectorsRoute]);

const connectorItem: NavMenuItem = {
  iconName: "lock",
  label: "Connectors",
  name: "connectors",
  targetRoute: {
    path: "/connectors",
    name: "connectors",
    component: () => import("@/connector/pages/Connectors.vue"),
  },
  rank: 60,
  isParent: false,
  hasParent: false,
};
// commenting register function. Oneteg Ui will not contain connector menu.
registerNavMenuItem(connectorItem);

const connectorsBreadcrumb: BreadcrumbParam = {
  routeName: "connectors",
  items: [
    {
      labelKey: "Auth.Home",
      route: "/dashboard",
      disabled: false,
      isTranslated: true,
      uniqueLabel: "",
    },
    {
      labelKey: "Common.Connectors",
      route: "",
      disabled: true,
      isTranslated: true,
      uniqueLabel: "",
    },
  ],
};

addBreadCrumbs([connectorsBreadcrumb]);
