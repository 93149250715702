import { createApp } from "vue/dist/vue.esm-bundler";
import Popper from "vue3-popper";
import Toast, { PluginOptions } from "vue-toastification";
import App from "./App.vue";
import { router } from "../router";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "../icon/font-awesome";
import i18n from "../i18n/i18n";
import "element-plus/dist/index.css";
import { init } from "./startup";
import "vue-toastification/dist/index.css";
import outsideClickDirective from "../../common/directives/outside-click";
import insideClickDirective from "../../common/directives/inside-click";
import { initializeValue } from "../global/oneteg.window";

initializeValue();
init();

const filterBeforeCreate = (toast, toasts) => {
  if (toasts.filter((t) => t.type === toast.type).length !== 0
    && toasts.filter((t) => t.message === toast.message).length !== 0) {
    return false;
  }
  return toast;
};

const toastOptions: PluginOptions = {
  transition: "Vue-Toastification__fade",
  maxToasts: 20,
  newestOnTop: true,
  filterBeforeCreate,
};

createApp(App)
  .use(i18n)
  .use(router)
  .use(Toast, toastOptions)
  .component("font-awesome-icon", FontAwesomeIcon)
  .component("Popper", Popper)
  .directive("outside-click", outsideClickDirective)
  .directive("inside-click", insideClickDirective)
  .mount("#app");
