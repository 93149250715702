import {
  createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw,
} from "vue-router";
import { TYPE } from "vue-toastification";
import i18n, { DEFAULT_LOCALE, setI18nLocale, SUPPORTED_LOCALES } from "@/core/i18n/i18n";
import UserLayout from "@/core/layout/User.vue";
import {
  isUserLoggedIn, authInfo, hasPermission,
} from "@/core/auth";
import { showAlert } from "@/core/popup";
import { selectedOrganizationRole } from "@/core/auth/user-role";
import { apiCall, apiCallParams, logResponse } from "@/core/api";
import predefinedRoutes from "./routes";

const PUBLIC_ROUTE_NAMES = ["login", "reset-password", "auth/new-password", "auth/activate-email"];

const { t } = i18n.global;

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      name: "local",
      path: `/:locale(${SUPPORTED_LOCALES.join("|")})`,
      component: {
        template: "<router-view></router-view>",
      },
      children: predefinedRoutes as RouteRecordRaw[],
    },
    {
      name: "global",
      path: "/:pathMatch(.*)*",
      beforeEnter: (to, from, next) => {
        let locale = localStorage.getItem("locale");
        if (!locale) {
          locale = DEFAULT_LOCALE;
        }
        return next(`/${locale}${to.fullPath}`);
      },
    } as RouteRecordRaw,
  ],
});

router.beforeEach((async (to:RouteLocationNormalized, from, next) => {
  if (to.name === "global") {
    return next();
  }

  const locale = to.params.locale.toString();
  localStorage.setItem("locale", locale);
  await setI18nLocale(locale);

  const authRequired = !PUBLIC_ROUTE_NAMES.includes(to.name.toString());
  if (authRequired && !isUserLoggedIn.value && to.name !== "login") {
    return next({
      name: "login",
      params: {
        locale,
      },
    });
  }
  if (to.name === "auth/new-password") {
    const id = to.params.id.toString();
    if (!await checkActivationExpiry(id)) {
      return next({
        name: "dashboard",
        params: { locale },
      });
    }
  }
  if (to.name === "auth/activate-email") {
    const id = to.params.id.toString();
    await activateNewEmail(id);
  }
  const { authorize }:any = to.meta;

  if (authorize && isUserLoggedIn.value && authInfo.value) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (authorize.length && !hasPermission(authorize[0])) {
      if (!selectedOrganizationRole.value) {
        if (await checkPrivilege(authInfo.value.roleId, authorize[0])) {
          return next();
        }
      }
      showAlert(TYPE.WARNING, t("NotAuthorized"));
      return next({
        name: "dashboard",
        params: { locale },
      });
    }
  }

  return next();
}));

export const { currentRoute } = router;

/** Loads a dynamic object from local storage.
 * @returns true if all properties in wrapper exist and loaded from local storage
 * @throws {StorageError}
 * @param routes
 */
export function addRoutes(routes: RouteRecordRaw[]): void {
  routes.forEach((route) => {
    route.meta = {
      layout: UserLayout,
      authorize: route.meta?.authorize,
    };
    route.path = `/:locale(en|tr)${route.path}`;
    router.addRoute(route);
  });
}

/** Sets a route as the user homepage.
 * @param routeName The name of the route
 * @returns true if such route is found and set as user homepage
 */
export function setHomepage(routeName: string): boolean {
  if (!router.hasRoute(routeName)) {
    return false;
  }

  const homeRoute: RouteRecordRaw = {
    path: "/:locale(en|tr)/",
    name: "home",
    redirect: {
      name: routeName,
    },
  };
  router.removeRoute("home");
  router.addRoute(homeRoute);

  return true;
}

export async function checkPrivilege(roleId:string, privilegeId:string):Promise<boolean> {
  const endpoint = "/roles/privilege";
  const response = await apiCallParams("GET", endpoint, { roleId, privilegeId });
  if (response.code === 200) {
    return response.message === "true";
  }
  logResponse(response);
  return false;
}

export async function checkActivationExpiry(id:string):Promise<any> {
  const endpoint = `/auth/new-password/${id}`;
  const response = await apiCall("GET", endpoint);
  if (response.code === 200) {
    return response.message;
  }
  if (response.code === 408) {
    showAlert(TYPE.ERROR, "Your request has expired. Please send new reset password request.");
    return false;
  }
  if (response.code === 409) {
    showAlert(TYPE.ERROR, "This link is outdated.");
    return false;
  }
  logResponse(response);
  return false;
}

export async function activateNewEmail(id:string):Promise<any> {
  const endpoint = `/auth/activate-email/${id}`;
  const response = await apiCall("PUT", endpoint);
  if (response.code === 200) {
    showAlert(TYPE.SUCCESS, "Your email address is verified successfully.");
    return response.message;
  }
  if (response.code === 409) {
    showAlert(TYPE.ERROR, "This link is outdated.");
    return false;
  }
  logResponse(response);
  return false;
}
