import { RouteRecordRaw } from "vue-router";
import { ref } from "vue";
import { cloneDeep } from "lodash-es";
import { createCustomError, getSyncedItem } from "@/core/util";

/**
 * This interface defines navigation menu item.
 * @param iconName A string defines the name of the icon.
 * @param label The name of the path to be matched.
 * @param name The name shown in navigation menu.
 * @param targetRoute defines route options.
 * @param rank Determines the position of the element we want to add in the list.
 * @param authority Determines the permission of the page.
 * @param isParent Determines that this is parent
 * @param hasParent Determines that this is child
 */
export interface NavMenuItem {
  iconName: string;
  label: string;
  name: string;
  targetRoute: RouteRecordRaw;
  rank: number;
  authority?: string;
  isParent: boolean;
  hasParent: boolean;
}

export interface BreadcrumbItem {
  labelKey: string,
  route: string,
  disabled: boolean,
  isTranslated: boolean,
  uniqueLabel: string,
}

export interface BreadcrumbParam {
  routeName: string,
  items: BreadcrumbItem[],
}

export const navMenuItems: NavMenuItem[] = [];
export const menuItemsWithParent: NavMenuItem[] = [];
export const menuItemsWithChildren: NavMenuItem[] = [];

export const breadCrumbMap = new Map<string, BreadcrumbItem[]>();
const defaultBreadcrumbList = [
  {
    labelKey: "Auth.Home",
    route: "/dashboard",
    disabled: false,
    isTranslated: true,
    uniqueLabel: "",
  },
];

export const selectedBreadcrumb = ref<BreadcrumbItem[]>([
  {
    labelKey: "Auth.Home",
    route: "/dashboard",
    disabled: false,
    isTranslated: true,
    uniqueLabel: "",
  },
]);

export const NavItemError = createCustomError("NavItemError");

const sidebarCollapsed = getSyncedItem<boolean>("sidebarCollapsed");
export { sidebarCollapsed };

export function registerNavMenuItem(navMenuItem: NavMenuItem): void {
  navMenuItems.forEach((item) => {
    if (item.rank !== undefined) {
      if (item.rank === navMenuItem.rank) {
        throw new NavItemError("Rank is Required!");
      }
    }
  });
  if (!navMenuItem.isParent && !navMenuItem.hasParent) {
    navMenuItems.push(navMenuItem);
  } else if (navMenuItem.isParent) {
    menuItemsWithParent.push(navMenuItem);
  } else if (navMenuItem.hasParent) {
    menuItemsWithChildren.push(navMenuItem);
  }
}

export function addBreadCrumbs(breadCrumbs: BreadcrumbParam[]): void {
  breadCrumbs.forEach((item) => {
    breadCrumbMap.set(item.routeName, item.items);
  });
}

export function switchBreadcrumbListByRouteName(routeName: string): void {
  selectedBreadcrumb.value = cloneDeep(breadCrumbMap.has(routeName)
    ? breadCrumbMap.get(routeName) : defaultBreadcrumbList);
}

export function changeListItemOfBreadcrumb(item: BreadcrumbItem): void {
  selectedBreadcrumb.value.pop();
  selectedBreadcrumb.value.push(item);
}
