import { RouteRecordRaw } from "vue-router";
import { addRoutes } from "@/core/router";
import {
  registerNavMenuItem, NavMenuItem, addBreadCrumbs, BreadcrumbParam,
} from "@/core/layout";

const webhookPayloadsRoute: RouteRecordRaw = {
  path: "/webhooks",
  name: "webhook",
  component: () => import("@/webhook/pages/WebhookPayload.vue"),
  meta: {
    authorize: ["WEBHOOK.LIST.VIEW"],
  },
};
addRoutes([webhookPayloadsRoute]);

const webhookPayloadItem: NavMenuItem = {
  iconName: "cogs",
  label: "Webhook",
  name: "webhooks",
  targetRoute: {
    path: "/webhooks",
    name: "webhooks",
    component: () => import("@/webhook/pages/WebhookPayload.vue"),
  },
  authority: "WEBHOOK.LIST.VIEW",
  rank: undefined,
  isParent: false,
  hasParent: false,
};
registerNavMenuItem(webhookPayloadItem);

const webhookPayloadBreadcrumb: BreadcrumbParam = {
  routeName: "webhooks",
  items: [
    {
      labelKey: "Auth.Home",
      route: "/dashboard",
      disabled: false,
      isTranslated: true,
      uniqueLabel: "",
    },
    {
      labelKey: "Webhook.Webhooks",
      route: "/webhooks",
      disabled: true,
      isTranslated: true,
      uniqueLabel: "",
    },
  ],
};
addBreadCrumbs([webhookPayloadBreadcrumb]);
