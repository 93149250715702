import { RouteRecordRaw } from "vue-router";
import { NavMenuItem, registerNavMenuItem } from "@/core/layout";
import { addRoutes } from "@/core/router";

const privilegeRoute: RouteRecordRaw = {
  path: "/privileges",
  name: "privileges",
  component: () => import("@/privilege/pages/Privileges.vue"),
  meta: {
    authorize: ["PRIVILEGES.LIST.VIEW"],
    breadCrumbs: [
      {
        name: "Home",
        route: "/dashboard",
        isLast: false,
      },
      {
        name: "Privileges",
        route: "",
        isLast: true,
      },
    ],
  },
};

const createPrivilegeRoute: RouteRecordRaw = {
  path: "/privileges/create",
  name: "createPrivileges",
  component: () => import("@/privilege/pages/EditPrivileges.vue"),
  meta: {
    authorize: ["PRIVILEGES.LIST.CREATE"],
    breadCrumbs: [
      {
        name: "Home",
        route: "/dashboard",
        isLast: false,
      },
      {
        name: "Privileges",
        route: "/privileges",
        isLast: false,
      },
      {
        name: "Create",
        route: "",
        isLast: true,
      },
    ],
  },
};

const editPrivilegeRoute: RouteRecordRaw = {
  path: "/privileges/edit/:id",
  name: "editPrivileges",
  component: () => import("@/privilege/pages/EditPrivileges.vue"),
  meta: {
    authorize: ["PRIVILEGES.LIST.EDIT"],
    breadCrumbs: [
      {
        name: "Home",
        route: "/dashboard",
        isLast: false,
      },
      {
        name: "Privileges",
        route: "/privileges",
        isLast: false,
      },
      {
        name: "Edit",
        route: "",
        isLast: true,
      },
    ],
  },
};

// commenting Privlege route so that user can't hit it directly on the url, Oneteg Ui will not contain privilege menu.
// addRoutes([privilegeRoute, createPrivilegeRoute, editPrivilegeRoute]);

const privilegesItem: NavMenuItem = {
  iconName: "shield-alt",
  label: "Privileges",
  name: "privileges",
  targetRoute: {
    path: "/privileges",
    name: "privileges",
    component: () => import("@/privilege/pages/Privileges.vue"),
  },
  authority: "PRIVILEGES.LIST.VIEW",
  rank: 15,
  isParent: false,
  hasParent: false,
};
// commenting register function. Oneteg Ui will not contain privilege menu.
// registerNavMenuItem(privilegesItem);
