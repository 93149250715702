import { computed, readonly, ref } from "vue";
import { NavigationFailure } from "vue-router";
import { TYPE } from "vue-toastification";
import { apiCall, logResponse, Response } from "@/core/api";
import { router } from "@/core/router";
import { showAlert } from "@/core/popup";
import { createCustomError, getSyncedItem } from "@/core/util";
import {
  changeOrganizationRole,
  getOrganizationRoles,
  organizationRoles,
  selectedOrganizationRole,
} from "@/core/auth/user-role";
import { checkPreferenceVersion, updateUserPreferences } from "@/core/preferences/preferences";
import i18n from "@/core/i18n/i18n";

export interface AuthInfo {
  username: string,
  accessToken: string,
  refreshToken: string,
  authorities: any[],
  organizationId: string,
  tenantId: string,
  userId: string,
  roleId: string,
  preferences: any
}

const { t } = i18n.global;
const authInfo = getSyncedItem<AuthInfo>("authInfo");
const authInfoView = readonly(authInfo);
export { authInfoView as authInfo };

export const isUserLoggedIn = computed(() => authInfo.value !== undefined);

export const loadingOrganization = ref(false);

export const AuthError = createCustomError("AuthError");

export async function login(username: string, password: string): Promise<boolean> {
  const response = await apiCall("POST", "/auth", {
    username,
    password,
  });

  if (response.code === 200) {
    authInfo.value = {
      username,
      accessToken: response.result[0].accessToken,
      refreshToken: response.result[0].refreshToken,
      authorities: undefined,
      organizationId: response.result[0].organizationId,
      tenantId: response.result[0].tenantId,
      userId: response.result[0].userId,
      roleId: undefined,
      preferences: checkPreferenceVersion(response.result[0].userPreferences),
    };
    const orgRoleResponse = await getOrganizationRoles(authInfo.value.userId);
    if (orgRoleResponse) {
      authInfo.value.organizationId = selectedOrganizationRole.value.organization.id;
      authInfo.value.roleId = selectedOrganizationRole.value.role.id;
      if (organizationRoles.value.length > 0) {
        await router.push("/dashboard");
        return true;
      }
    }
    await logout();
    logResponse(response);
    return false;
  }

  if (response.code === 401 && response.message === "INVALID_CREDENTIALS") {
    showAlert(TYPE.ERROR, "The username or password you entered is incorrect.");
    return false;
  } if (response.code === 401 && response.message === "INVALID_TENANT") {
    showAlert(TYPE.ERROR, "Tenant not found.");
    return false;
  } if (response.code === 403 && response.message === "INACTIVE_USER") {
    showAlert(TYPE.ERROR, "User is not activated, please verify your email.");
    return false;
  } if (response.code === 400 && response.message === t("Auth.OrgIdNotPresent")) {
    showAlert(TYPE.ERROR, "OrgId is not present in the Request Header.");
    return false;
  } if (response.code === 400 && response.message === t("Auth.TenantIdNotPresent")) {
    showAlert(TYPE.ERROR, "TenantId is not present in the Request Header.");
    return false;
  } if (response.code === 500) {
    showAlert(TYPE.ERROR, "An unexpected error has occurred in the server.");
    return false;
  }
  logResponse(response);
  return false;
}

export async function logout(): Promise<void | NavigationFailure> {
  authInfo.value = undefined;
  organizationRoles.value = undefined;
  selectedOrganizationRole.value = undefined;
  await router.push("/login");
}

/** Refreshes access and refresh tokens using the current refresh token.
 * @returns undefined if tokens are refreshed successfully, otherwise the error response
 */
export async function refreshTokens(): Promise<void> {
  const body = {
    token: authInfo.value?.refreshToken,
  };
  const response = await apiCall("POST", "/auth/refresh", body);

  if (response.code === 200) {
    authInfo.value.accessToken = response.result[0].accessToken;
    authInfo.value.refreshToken = response.result[0].refreshToken;
    authInfo.value.organizationId = response.result[0].organizationId;
    authInfo.value.tenantId = response.result[0].tenantId;
    authInfo.value.userId = response.result[0].userId;
    authInfo.value.preferences = checkPreferenceVersion(response.result[0].userPreferences);
    if (!selectedOrganizationRole.value && organizationRoles.value.length === 0) {
      const orgRoleResponse = await getOrganizationRoles(authInfo.value.userId);
      if (orgRoleResponse) {
        authInfo.value.organizationId = selectedOrganizationRole.value.organization.id;
        authInfo.value.roleId = selectedOrganizationRole.value.role.id;
      } else {
        await logout();
      }
    }
    return;
  }

  await logout();
  logResponse(response);
  throw new AuthError("Tokens could not be refreshed. User needs to login again.");
}

export function isAccessTokenExpired(response: Response): boolean {
  return response.code === 401 && response.message === "INVALID_ACCESS_TOKEN";
}

export function hasPermission(permission: string): boolean {
  if (selectedOrganizationRole.value) {
    const index = selectedOrganizationRole.value.role.privileges.findIndex((item) => item.privilegeId === permission);
    return index !== -1;
  }
  return false;
}

export async function resetPassword(email: string): Promise<boolean> {
  const endpoint = `/users/reset-password/${email}`;
  const response = await apiCall("GET", endpoint);

  if (response.code === 200) {
    return true;
  }

  if (response.code === 401) {
    showAlert(TYPE.ERROR, response.message);
    return false;
  }

  logResponse(response);
  return false;
}

export async function setSelectedOrganizationRole(organizationRole: any, target?: string): Promise<void> {
  loadingOrganization.value = true;
  await changeOrganizationRole(organizationRole)
    .then(async (result) => {
      if (result) {
        authInfo.value.organizationId = organizationRole.organization.id;
        authInfo.value.roleId = organizationRole.role.id;
        await router.push("/dashboard");
        if (target) {
          await router.push(target);
        }
        window.location.reload();
      } else {
        showAlert(TYPE.ERROR, "Unable to switch organization.");
      }
    });
  loadingOrganization.value = false;
}

export async function updatePreferences(preferences: any): Promise<boolean> {
  authInfo.value.preferences = preferences;
  return updateUserPreferences(authInfo.value.userId, preferences);
}

export async function updateSinglePreference(key: string, preferenceVal: any): Promise<boolean> {
  const preferenceObj = authInfo.value.preferences[key];
  if (preferenceObj) {
    preferenceObj.value = preferenceVal;
    authInfo.value.preferences[key] = preferenceObj;
    const response = updateUserPreferences(authInfo.value.userId, authInfo.value.preferences);
    return !!response;
  }
  return false;
}

export async function updateMultiplePreference(updatedPreferences: any):Promise<boolean> {
  Object.entries(updatedPreferences).forEach(([key, value]: any[]) => {
    const preferenceObj = authInfo.value.preferences[key];
    if (preferenceObj) {
      preferenceObj.value = value || preferenceObj.default;
      authInfo.value.preferences[key] = preferenceObj;
    }
  });
  const response = await updateUserPreferences(authInfo.value.userId, authInfo.value.preferences);
  return !!response;
}
