import { TYPE } from "vue-toastification";
import { Response } from "@/core/api";
import { showAlert } from "@/core/popup";

export function logResponse(response: Response): void {
  try {
    if (response.code === 403) {
      showAlert(TYPE.ERROR, "You do not have the required permission for this operation.");
    }
  } catch (e) {
    console.log(e);
  }
}

export function handleResponse(response: Response): void {
  switch (response.code) {
    case 400:
      showAlert(TYPE.ERROR, "The format of the request is invalid.");
      return;
    case 401:
      showAlert(TYPE.ERROR, "Your session is expired. Please log in again.");
      return;
    case 403:
      showAlert(TYPE.ERROR, "You do not have the required permission for this operation.");
      return;
    case 404:
      showAlert(TYPE.ERROR, "The resource could not be found in the server.");
      return;
    case 409:
      showAlert(TYPE.ERROR, "There is a conflict with the state of the resource.");
      return;

    case 500:
      showAlert(TYPE.ERROR, "The server has encountered an internal error.");
      return;
    case 502:
      showAlert(TYPE.ERROR, "An error has occurred in gateway or proxy server.");
      return;
    case 503:
      showAlert(TYPE.ERROR, "The server is unavailable at the moment.");
      return;

    case 900:
      showAlert(TYPE.ERROR, "The server could not be reached.");
      return;
    case 901:
      showAlert(TYPE.ERROR, "Connection to the server has timed out.");
      return;

    default:
      // Error could not be identified by error code. Error type will be checked now.
      switch (response.type) {
        case "INFORMATIONAL":
          showAlert(TYPE.INFO, "The request is received and understood.");
          return;
        case "SUCCESS":
          showAlert(TYPE.SUCCESS, "The operation is successful.");
          return;
        case "REDIRECTION":
          showAlert(TYPE.WARNING, "Target has been moved to a different location.");
          return;
        case "CLIENT_ERROR":
          showAlert(TYPE.ERROR, "There is a client error in the request.");
          return;
        case "SERVER_ERROR":
          showAlert(TYPE.ERROR, "An unexpected error has occurred in the server.");
          return;
        case "CONNECTION_ERROR":
          showAlert(TYPE.ERROR, "Connection to the server could not be established.");
          return;
        default:
          showAlert(TYPE.ERROR, "An unexpected error has occurred.");
      }
  }
}
