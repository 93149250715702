import { RouteRecordRaw } from "vue-router";
import { addRoutes } from "@/core/router";
import {
  registerNavMenuItem, NavMenuItem, addBreadCrumbs, BreadcrumbParam,
} from "@/core/layout";

const flowsRoute: RouteRecordRaw = {
  path: "/flows",
  name: "flows",
  component: () => import("@/flow/pages/Flows.vue"),
  meta: {
    authorize: ["FLOWS.LIST.VIEW"],
  },
};
const createFlowsRoute: RouteRecordRaw = {
  path: "/flows/create",
  name: "createFlows",
  component: () => import("@/flow/pages/EditFlow.vue"),
  meta: {
    authorize: ["FLOWS.LIST.CREATE"],
  },
};
const editFlowsRoute: RouteRecordRaw = {
  path: "/flows/edit/:id/:version",
  name: "editFlows",
  component: () => import("@/flow/pages/EditFlow.vue"),
  meta: {
    authorize: ["FLOWS.LIST.EDIT"],
  },
};
const exportFlowRoute: RouteRecordRaw = {
  path: "/flows/export/:id/:version",
  name: "exportFlows",
  component: () => import("@/flow/pages/ExportWizard.vue"),
  meta: {
    authorize: ["FLOWS.LIST.EXPORT"],
  },
};
addRoutes([flowsRoute, createFlowsRoute, editFlowsRoute, exportFlowRoute]);

const flowItem: NavMenuItem = {
  iconName: "network-wired",
  label: "Flows",
  name: "flows",
  targetRoute: {
    path: "/flows",
    name: "flows",
    component: () => import("@/flow/pages/Flows.vue"),
  },
  rank: 90,
  authority: "FLOWS.LIST.VIEW",
  isParent: false,
  hasParent: false,
};
registerNavMenuItem(flowItem);

const flowsBreadcrumb: BreadcrumbParam = {
  routeName: "flows",
  items: [
    {
      labelKey: "Auth.Home",
      route: "/dashboard",
      disabled: false,
      isTranslated: true,
      uniqueLabel: "",
    },
    {
      labelKey: "Flow.Flows",
      route: "/flows",
      disabled: true,
      isTranslated: true,
      uniqueLabel: "",
    },
  ],
};

const createFlowBreadCrumb: BreadcrumbParam = {
  routeName: "createFlows",
  items: [
    {
      labelKey: "Auth.Home",
      route: "/dashboard",
      disabled: false,
      isTranslated: true,
      uniqueLabel: "",
    },
    {
      labelKey: "Flow.Flows",
      route: "/flows",
      disabled: false,
      isTranslated: true,
      uniqueLabel: "",
    },
    {
      labelKey: "Common.Create",
      route: "",
      disabled: true,
      isTranslated: true,
      uniqueLabel: "",
    },
  ],
};

const editFlowBreadCrumb: BreadcrumbParam = {
  routeName: "editFlows",
  items: [
    {
      labelKey: "Auth.Home",
      route: "/dashboard",
      disabled: false,
      isTranslated: true,
      uniqueLabel: "",
    },
    {
      labelKey: "Flow.Flows",
      route: "/flows",
      disabled: false,
      isTranslated: true,
      uniqueLabel: "",
    },
    {
      labelKey: "Common.Edit",
      route: "",
      disabled: true,
      isTranslated: false,
      uniqueLabel: "",
    },
  ],
};

const exportFlowBreadcrumb: BreadcrumbParam = {
  routeName: "exportFlows",
  items: [
    {
      labelKey: "Auth.Home",
      route: "/dashboard",
      disabled: false,
      isTranslated: true,
      uniqueLabel: "",
    },
    {
      labelKey: "Flow.Flows",
      route: "/flows",
      disabled: false,
      isTranslated: true,
      uniqueLabel: "",
    },
    {
      labelKey: "Flow.Export",
      route: "",
      disabled: true,
      isTranslated: true,
      uniqueLabel: "",
    },
  ],
};

addBreadCrumbs([flowsBreadcrumb, createFlowBreadCrumb, editFlowBreadCrumb, exportFlowBreadcrumb]);
