import {
  authInfo, isUserLoggedIn, logout, refreshTokens,
} from "@/core/auth";
import { getOrganizationRoles, organizationRoles } from "@/core/auth/user-role";

export function init(): void {
  loadFeaturePackages();

  if (isUserLoggedIn.value) {
    refreshTokens();
  }
  if (isUserLoggedIn.value && organizationRoles.value.length === 0) {
    getOrganizationRoles(authInfo.value.userId).then(async (response) => {
      if (!response) {
        await logout();
      }
    });
  }
}

function loadFeaturePackages(): void {
  const files = require.context("@", true, /^.\/((?!\/).)+\/index\.(js|ts)$/);
  files.keys().forEach((file) => {
    files(file);
  });
}
