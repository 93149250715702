import { RouteRecordRaw } from "vue-router";
import { addRoutes } from "@/core/router";
import {
  addBreadCrumbs, BreadcrumbParam, NavMenuItem, registerNavMenuItem,
} from "@/core/layout";

const activitiesRoute: RouteRecordRaw = {
  path: "/activities",
  name: "activities",
  component: () => import("@/activity/pages/Activities.vue"),
  meta: {
    authorize: ["ACTIVITIES.LIST.VIEW"],
  },
};

const exportSummaryRoute: RouteRecordRaw = {
  path: "/activities/:activityId",
  name: "executionSummary",
  component: () => import("@/activity/pages/ExecutionSummary.vue"),
  meta: {
    authorize: ["ACTIVITIES.LIST.VIEW"],
  },
};
addRoutes([activitiesRoute, exportSummaryRoute]);

const activityItem: NavMenuItem = {
  iconName: "history",
  label: "Activities",
  name: "activities",
  targetRoute: {
    path: "/activities",
    name: "activities",
    component: () => import("@/activity/pages/Activities.vue"),
  },
  authority: "ACTIVITIES.LIST.VIEW",
  rank: 80,
  isParent: false,
  hasParent: false,
};

registerNavMenuItem(activityItem);

const activityBreadcrumb: BreadcrumbParam = {
  routeName: "activities",
  items: [
    {
      labelKey: "Auth.Home",
      route: "/dashboard",
      disabled: false,
      isTranslated: true,
      uniqueLabel: "",
    },
    {
      labelKey: "Common.Activities",
      route: "",
      disabled: true,
      isTranslated: true,
      uniqueLabel: "",
    },
  ],
};

const executionSummaryBreadCrumb: BreadcrumbParam = {
  routeName: "executionSummary",
  items: [
    {
      labelKey: "Auth.Home",
      route: "/dashboard",
      disabled: false,
      isTranslated: true,
      uniqueLabel: "",
    },
    {
      labelKey: "Common.Activities",
      route: "/activities",
      disabled: true,
      isTranslated: true,
      uniqueLabel: "",
    },
    {
      labelKey: "Common.Name",
      route: "",
      disabled: true,
      isTranslated: true,
      uniqueLabel: "",
    },
  ],
};

addBreadCrumbs([activityBreadcrumb, executionSummaryBreadCrumb]);
