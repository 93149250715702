import { createI18n } from "vue-i18n";

export const DEFAULT_LOCALE = "en";
export const SUPPORTED_LOCALES = ["en", "tr"];

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
});

export default i18n;

export async function setI18nLocale(locale: string): Promise<boolean> {
  if (!SUPPORTED_LOCALES.includes(locale)) {
    return false;
  }

  if (i18n.global.locale.value !== locale) {
    const messages = await import(`./locales/${locale}.json`);
    i18n.global.setLocaleMessage(locale, messages.default);
    document.querySelector("html").setAttribute("lang", locale);
    i18n.global.locale.value = locale;
  }

  return true;
}
