import axios from "axios";

/**
 * Retrieves the environment variable.
 * @param key The key of the environment variable.
 * @returns The value of the environment variable.
 */
export function getEnv(key: string): string {
  return process.env[`VUE_APP_${key}`];
}

export async function getParameter(key: string): Promise<string> {
  try {
    if (getEnv("ENVIRONMENT") === "DEVELOPMENT" || getEnv("ENVIRONMENT") === "KUBERNETES_DEV") {
      return getEnv(key);
    }
    const configData = await axios.get("/config.json");
    const data = JSON.parse(JSON.stringify(configData.data));
    return data[`VUE_APP_${key}`] ? data[`VUE_APP_${key}`] : getEnv(key);
  } catch (e) {
    return getEnv(key);
  }
}
