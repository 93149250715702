import UserLayout from "@/core/layout/User.vue";

const DummyHomepage = (): Promise<any> => import("@/core/layout/DummyHomepage.vue");
const Login = (): Promise<any> => import("@/core/auth/Login.vue");
const ResetPassword = (): Promise<any> => import("@/core/auth/ResetPassword.vue");
const NewPassword = (): Promise<any> => import("@/core/auth/NewPassword.vue");
const Profile = (): Promise<any> => import("@/core/layout/Profile.vue");
const NotFound = (): Promise<any> => import("@/core/router/pages/NotFound.vue");

const routes = [
  {
    path: "",
    name: "home",
    component: DummyHomepage,
    meta: { layout: UserLayout },
  },
  {
    name: "login",
    path: "login",
    component: Login,
    // meta: {layout: GuestLayout}
  },
  {
    name: "reset-password",
    path: "reset-password",
    component: ResetPassword,
  },
  {
    name: "auth/new-password",
    path: "auth/new-password/:id",
    component: NewPassword,
  },
  {
    name: "auth/activate-email",
    path: "auth/activate-email/:id",
    component: Login,
  },
  {
    path: "profile",
    name: "profile",
    component: Profile,
    meta: { layout: UserLayout },
  },
  {
    name: "notFound",
    path: ":unknown+",
    component: NotFound,
    // meta: {layout: GuestLayout}
  },
];

export default routes;
