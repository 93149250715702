import { cloneDeep } from "lodash-es";
import { ref, UnwrapRef } from "vue";
import { apiCall } from "@/core/api";

export interface OrganizationRole {
  active: boolean,
  createdAt: Date,
  createdBy: any,
  default: boolean,
  deleted: boolean,
  id: string,
  modifiedBy: any,
  organization: any,
  role: any,
  updatedAt: Date,
  user: any,
}

export const organizationRoles = ref(new Array<OrganizationRole>());

export const selectedOrganizationRole = ref<OrganizationRole>();

export async function getOrganizationRoles(userId: string): Promise<boolean> {
  const resource = `/organization-role/user/${userId}`;
  const response = await apiCall("GET", resource);

  if (response.code === 200) {
    organizationRoles.value = response.result;
    selectedOrganizationRole.value = findDefaultOrganization();
    return true;
  }
  return false;
}

export function findDefaultOrganization(): OrganizationRole {
  let selectedItem;
  organizationRoles.value.forEach((item) => {
    if (item.default) {
      selectedItem = item;
    }
  });
  if (!selectedItem && organizationRoles.value.length > 0) {
    // eslint-disable-next-line prefer-destructuring
    selectedItem = organizationRoles.value[0];
  }
  return selectedItem;
}

export async function changeOrganizationRole(organizationRole: OrganizationRole): Promise<any> {
  let result = false;
  selectedOrganizationRole.value = cloneDeep(organizationRole);
  if (organizationRole.organization.id && organizationRole.role.id) {
    await apiCall("POST", "/organization-role/update", organizationRole)
      .then((response) => {
        if (response.code === 200) {
          result = true;
        }
      });
  }
  return result;
}

export function findOrganizationRoleByOrgId(organizationId: string): UnwrapRef<OrganizationRole> {
  return organizationRoles.value.find((obj) => obj.organization.id === organizationId);
}
