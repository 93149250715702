import { RouteRecordRaw } from "vue-router";
import { addRoutes, setHomepage } from "@/core/router";
import {
  registerNavMenuItem, NavMenuItem, addBreadCrumbs, BreadcrumbParam,
} from "@/core/layout";

const Dashboard = () => import("@/dashboard/pages/Dashboard.vue");
const dashboardRoute: RouteRecordRaw = {
  path: "/dashboard",
  name: "dashboard",
  component: Dashboard,
  meta: {},
};

const dashboardItem: NavMenuItem = {
  iconName: "th",
  label: "Dashboard",
  name: "dashboard",
  targetRoute: {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/dashboard/pages/Dashboard.vue"),
  },
  rank: 100,
  isParent: false,
  hasParent: false,
};

addRoutes([dashboardRoute]);
setHomepage("dashboard");
registerNavMenuItem(dashboardItem);

const dashboardBreadcrumb: BreadcrumbParam = {
  routeName: "dashboard",
  items: [
    {
      labelKey: "Common.Dashboard",
      route: "/dashboard",
      disabled: true,
      isTranslated: true,
      uniqueLabel: "",
    },
  ],
};

addBreadCrumbs([dashboardBreadcrumb]);
