/**
 * Creates a named custom error class with the given name
 * @param name The name of error class
 * @returns Created error class
 */
export function createCustomError(name: string): typeof CustomError {
  const CustomError = class extends Error {
    constructor(...args) {
      super(...args);
      Object.setPrototypeOf(this, CustomError.prototype);
      this.name = name;
    }
  };
  Object.defineProperty(CustomError, "name", { value: name });
  return CustomError;
}
