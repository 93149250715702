import { RouteRecordRaw } from "vue-router";
import {
  NavMenuItem, registerNavMenuItem, addBreadCrumbs, BreadcrumbParam,
} from "@/core/layout";
import { addRoutes } from "@/core/router";

const connectionsRoute: RouteRecordRaw = {
  path: "/connections",
  name: "connections",
  component: () => import("@/connection/pages/Connections.vue"),
  meta: {
    authorize: ["CONNECTIONS.LIST.VIEW"],
  },
};

const createConnectionRoute: RouteRecordRaw = {
  path: "/connections/create",
  name: "createConnection",
  component: () => import("@/connection/pages/EditConnection.vue"),
  meta: {
    authorize: ["CONNECTIONS.LIST.CREATE"],
  },
};

const editConnectionRoute: RouteRecordRaw = {
  path: "/connections/edit/:id",
  name: "editConnection",
  component: () => import("@/connection/pages/EditConnection.vue"),
  meta: {
    authorize: ["CONNECTIONS.LIST.EDIT"],
  },
};

addRoutes([connectionsRoute, createConnectionRoute, editConnectionRoute]);

const connectionItem: NavMenuItem = {
  iconName: "link",
  label: "Connections",
  name: "connections",
  targetRoute: {
    path: "/connections",
    name: "connections",
    component: () => import("@/connection/pages/Connections.vue"),
  },
  rank: 70,
  authority: "CONNECTIONS.LIST.VIEW",
  isParent: false,
  hasParent: false,
};
registerNavMenuItem(connectionItem);

const connectionBreadcrumb: BreadcrumbParam = {
  routeName: "connections",
  items: [
    {
      labelKey: "Auth.Home",
      route: "/dashboard",
      disabled: false,
      isTranslated: true,
      uniqueLabel: "",
    },
    {
      labelKey: "Common.Connections",
      route: "/connections",
      disabled: true,
      isTranslated: true,
      uniqueLabel: "",
    },
  ],
};

const createConnectionsBreadcrumb: BreadcrumbParam = {
  routeName: "createConnection",
  items: [
    {
      labelKey: "Auth.Home",
      route: "/dashboard",
      disabled: false,
      isTranslated: true,
      uniqueLabel: "",
    },
    {
      labelKey: "Common.Connections",
      route: "/connections",
      disabled: false,
      isTranslated: true,
      uniqueLabel: "",
    },
    {
      labelKey: "Common.Create",
      route: "",
      disabled: true,
      isTranslated: true,
      uniqueLabel: "",
    },
  ],
};

const editConnectionBreadcrumb: BreadcrumbParam = {
  routeName: "editConnection",
  items: [
    {
      labelKey: "Auth.Home",
      route: "/dashboard",
      disabled: false,
      isTranslated: true,
      uniqueLabel: "",
    },
    {
      labelKey: "Common.Connections",
      route: "/connections",
      disabled: false,
      isTranslated: true,
      uniqueLabel: "",
    },
    {
      labelKey: "Common.Edit",
      route: "",
      disabled: true,
      isTranslated: true,
      uniqueLabel: "",
    },
  ],
};

addBreadCrumbs([connectionBreadcrumb, createConnectionsBreadcrumb, editConnectionBreadcrumb]);
