import { ElMessageBox } from "element-plus";

type DialogType = "error" | "success" | "warning" | "info";

export async function showConfirm(type: DialogType, title: string, message: string)
  : Promise<boolean> {
  try {
    await ElMessageBox.confirm(`<div style="max-height: 200px; overflow-y: auto;">${message}</div>`, title, {
      type,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      showClose: true,
      dangerouslyUseHTMLString: true,
    });
  } catch {
    return false;
  }
  return true;
}
