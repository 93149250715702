import { authInfo } from "@/core/auth";
import { apiCall, logResponse } from "@/core/api";

export const defaultPreference = {
  version: "v1.1",
  pageSize: {
    default: 10,
    value: 25,
    type: "number-enum",
    options: [10, 25, 50, 100],
    label: "Preferences.PageSize",
    description: "Preferences.PageSizeDesc",
  },
  flowsSortField: {
    default: "createdAt",
    value: "createdAt",
    type: "enum",
    options: ["name", "createdAt", "active"],
    label: "Preferences.FlowsSortField",
    description: "Preferences.FlowsSortFieldDesc",
  },
  flowsSortOrder: {
    default: "descending",
    value: "descending",
    type: "enum",
    options: ["ascending", "descending"],
    label: "Preferences.FlowsSortOrder",
    description: "Preferences.SortOrderDesc",
  },
  webhooksSortField: {
    default: "createdAt",
    value: "createdAt",
    type: "enum",
    options: ["name", "webhookPayloadStatus", "activityStatus", "createdAt"],
    label: "Preferences.WebhooksSortField",
    description: "Preferences.WebhooksSortFieldDesc",
  },
  webhooksSortOrder: {
    default: "descending",
    value: "descending",
    type: "enum",
    options: ["ascending", "descending"],
    label: "Preferences.WebhooksSortOrder",
    description: "Preferences.SortOrderDesc",
  },
  activitiesSortField: {
    default: "startedOn",
    value: "startedOn",
    type: "enum",
    options: ["flowName", "flowStatus", "startedOn", "finishedOn"],
    label: "Preferences.ActivitiesSortField",
    description: "Preferences.ActivitiesSortFieldDesc",
  },
  activitiesSortOrder: {
    default: "descending",
    value: "descending",
    type: "enum",
    options: ["ascending", "descending"],
    label: "Preferences.ActivitiesSortOrder",
    description: "Preferences.SortOrderDesc",
  },
  connectionsSortField: {
    default: "createdAt",
    value: "createdAt",
    type: "enum",
    options: ["name", "createdAt"],
    label: "Preferences.ConnectionsSortField",
    description: "Preferences.ConnectionsSortFieldDesc",
  },
  connectionsSortOrder: {
    default: "descending",
    value: "descending",
    type: "enum",
    options: ["ascending", "descending"],
    label: "Preferences.ConnectionsSortOrder",
    description: "Preferences.SortOrderDesc",
  },
  connectorsSortField: {
    default: "name",
    value: "name",
    type: "enum",
    options: ["name", "connectorType"],
    label: "Preferences.ConnectorsSortField",
    description: "Preferences.ConnectorsSortFieldDesc",
  },
  connectorsSortOrder: {
    default: "ascending",
    value: "ascending",
    type: "enum",
    options: ["ascending", "descending"],
    label: "Preferences.ConnectorsSortOrder",
    description: "Preferences.SortOrderDesc",
  },
  usersSortField: {
    default: "name",
    value: "name",
    type: "enum",
    options: ["name", "createdAt"],
    label: "Preferences.UsersSortField",
    description: "Preferences.UsersSortFieldDesc",
  },
  usersSortOrder: {
    default: "ascending",
    value: "ascending",
    type: "enum",
    options: ["ascending", "descending"],
    label: "Preferences.UsersSortOrder",
    description: "Preferences.SortOrderDesc",
  },
  organizationsSortField: {
    default: "name",
    value: "name",
    type: "enum",
    options: ["name", "createdAt"],
    label: "Preferences.OrganizationsSortField",
    description: "Preferences.OrganizationsSortFieldDesc",
  },
  organizationsSortOrder: {
    default: "ascending",
    value: "ascending",
    type: "enum",
    options: ["ascending", "descending"],
    label: "Preferences.OrganizationsSortOrder",
    description: "Preferences.SortOrderDesc",
  },
  rolesSortField: {
    default: "name",
    value: "name",
    type: "enum",
    options: ["name", "createdAt"],
    label: "Preferences.RolesSortField",
    description: "Preferences.RolesSortFieldDesc",
  },
  rolesSortOrder: {
    default: "ascending",
    value: "ascending",
    type: "enum",
    options: ["ascending", "descending"],
    label: "Preferences.RolesSortOrder",
    description: "Preferences.SortOrderDesc",
  },
  editFlowBackground: {
    default: "#b5c0cd",
    value: "rgba(191, 181, 205, 1)",
    type: "color",
    label: "Preferences.Background",
    description: "Preferences.EditBackgroundDesc",
  },
  editStepBoxSize: {
    default: "medium",
    value: "medium",
    type: "enum",
    options: ["small", "medium", "large"],
    label: "Preferences.EditStepSize",
    description: "Preferences.EditStepSizeDesc",
  },
  filterBoxSize: {
    default: "large",
    value: "large",
    type: "enum",
    options: ["small", "medium", "large"],
    label: "Preferences.FilterSize",
    description: "Preferences.FilterSizeDesc",
  },
  conditionBoxSize: {
    default: "large",
    value: "large",
    type: "enum",
    options: ["small", "medium", "large"],
    label: "Preferences.ConditionSize",
    description: "Preferences.ConditionSizeDesc",
  },
  detailsBoxSize: {
    default: "small",
    value: "small",
    type: "enum",
    options: ["small", "medium", "large"],
    label: "Preferences.DetailsSize",
    description: "Preferences.DetailsSizeDesc",
  },
  userVariableBoxSize: {
    default: "small",
    value: "small",
    type: "enum",
    options: ["small", "medium", "large"],
    label: "Preferences.UserVariableSize",
    description: "Preferences.UserVariableSizeDesc",
  },
  accordionColors: {
    default: ["#C0DEFF", "#ADA2FF", "#BCCEF8", "#ECC5FB",
      "#E5E0FF", "#CAB8FF", "#DEEDF0", "#F6DFEB",
      "#E3F6FF", "#C780FA"],
    value: ["#C0DEFF", "#ADA2FF", "#BCCEF8", "#ECC5FB",
      "#E5E0FF", "#CAB8FF", "#DEEDF0", "#F6DFEB",
      "#E3F6FF", "#C780FA"],
    type: "color-array",
    label: "Preferences.AccordionColors",
    description: "Preferences.AccordionColorsDesc",
  },
};

export function checkPreferenceVersion(preferences: any): any {
  try {
    if (preferences) {
      const preferenceObj = JSON.parse(preferences);
      if (preferenceObj.version !== defaultPreference.version) {
        Object.keys(preferenceObj)
          .forEach((key) => {
            if (key !== "version" && defaultPreference[key]) {
              defaultPreference[key] = preferenceObj[key];
            }
          });
        return defaultPreference;
      }
      return preferenceObj;
    }
    return defaultPreference;
  } catch (e) {
    return defaultPreference;
  }
}

export function getPreferenceValue(key: string): any {
  const preference = authInfo.value.preferences[key];
  if (preference) {
    return preference.value ? preference.value : preference.default;
  }
  return null;
}

export async function updateUserPreferences(userId: string, preferences: any): Promise<any> {
  const endpoint = "/users/preferences";
  const response = await apiCall("POST", endpoint, {
    userId,
    preferences: JSON.stringify(preferences),
  });
  if (response.code === 200) {
    return true;
  }
  logResponse(response);
  return false;
}
