/* eslint arrow-body-style: ["error", "always"] */
/* eslint-env es6 */
import { getValue, setValue } from "@/core/global/oneteg.window";

export default {
  mounted(el, binding) {
    el.ClickInsideHandler = (event) => {
      const isNotClickDisabled = !checkHasInsideElement(binding.value.disabledScopeElements, event);
      const isAvailable = (el === event.target || el.contains(event.target)) && isNotClickDisabled;

      if ((isAvailable || binding.value?.injectable) && getValue("isAvailableStartingElementForInsideClick")) {
        binding.value.handle(isAvailable);
      }
    };
    el.StartHandler = (event) => {
      setValue("isAvailableStartingElementForInsideClick", checkHasInsideElement(binding.value.eventStartScopeElements, event));
    };
    document.body.addEventListener(binding.value?.eventListenerType || "click", el.ClickInsideHandler);
    if (binding.value?.eventStartScopeElements && binding.value?.eventStartListenerType) {
      document.body.addEventListener(binding.value?.eventStartListenerType || "click", el.StartHandler);
    }
  },
  beforeUnmount(el, binding) {
    document.body.removeEventListener(binding.value?.eventListenerType || "click", el.ClickInsideHandler);
    document.body.removeEventListener(binding.value?.eventStartListenerType || "click", el.StartHandler);
  },
};

function checkHasInsideElement(elements, event) {
  return elements.every((disableElementSelector) => {
    return [...document.querySelectorAll(disableElementSelector)].some((disableElement) => {
      return disableElement === event.target || disableElement.contains(event.target);
    });
  });
}
