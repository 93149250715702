import { RouteRecordRaw } from "vue-router";
import {
  NavMenuItem, registerNavMenuItem, addBreadCrumbs, BreadcrumbParam,
} from "@/core/layout";
import { addRoutes } from "@/core/router";

const rolesRoute: RouteRecordRaw = {
  path: "/roles",
  name: "roles",
  component: () => import("@/role/pages/Roles.vue"),
  meta: {
    authorize: ["ROLES.LIST.VIEW"],
  },
};

const editRolesRoute: RouteRecordRaw = {
  path: "/roles/edit/:id",
  name: "editRoles",
  component: () => import("@/role/pages/EditRoles.vue"),
  meta: {
    authorize: ["ROLES.LIST.EDIT"],
  },
};

const createRolesRoute: RouteRecordRaw = {
  path: "/roles/create",
  name: "createRoles",
  component: () => import("@/role/pages/EditRoles.vue"),
  meta: {
    authorize: ["ROLES.LIST.CREATE"],
  },
};

addRoutes([rolesRoute, editRolesRoute, createRolesRoute]);

const roleItem: NavMenuItem = {
  iconName: "server",
  label: "Roles",
  name: "roles",
  targetRoute: {
    path: "/roles",
    name: "roles",
    component: () => import("@/role/pages/Roles.vue"),
  },
  authority: "ROLES.LIST.VIEW",
  rank: 10,
  isParent: false,
  hasParent: false,
};

registerNavMenuItem(roleItem);

const roleBreadcrumb: BreadcrumbParam = {
  routeName: "roles",
  items: [
    {
      labelKey: "Auth.Home",
      route: "/dashboard",
      disabled: false,
      isTranslated: true,
      uniqueLabel: "",
    },
    {
      labelKey: "Role.Roles",
      route: "/roles",
      disabled: true,
      isTranslated: true,
      uniqueLabel: "",
    },
  ],
};

const createRoleBreadcrumb: BreadcrumbParam = {
  routeName: "createRoles",
  items: [
    {
      labelKey: "Auth.Home",
      route: "/dashboard",
      disabled: false,
      isTranslated: true,
      uniqueLabel: "",
    },
    {
      labelKey: "Role.Roles",
      route: "/roles",
      disabled: false,
      isTranslated: true,
      uniqueLabel: "",
    },
    {
      labelKey: "Common.Create",
      route: "",
      disabled: true,
      isTranslated: true,
      uniqueLabel: "",
    },
  ],
};

const editRoleBreadcrumb: BreadcrumbParam = {
  routeName: "editRoles",
  items: [
    {
      labelKey: "Auth.Home",
      route: "/dashboard",
      disabled: false,
      isTranslated: true,
      uniqueLabel: "",
    },
    {
      labelKey: "Role.Roles",
      route: "/roles",
      disabled: false,
      isTranslated: true,
      uniqueLabel: "",
    },
    {
      labelKey: "Common.Edit",
      route: "",
      disabled: true,
      isTranslated: true,
      uniqueLabel: "",
    },
  ],
};

addBreadCrumbs([roleBreadcrumb, createRoleBreadcrumb, editRoleBreadcrumb]);
