import { Ref, ref, watch } from "vue";
import { createCustomError } from "@/core/util";

export const StorageError = createCustomError("StorageError");

/** Returns an item from local storage wrapped inside a ref.
 * Any change in its value will be saved to local storage automatically.
 * If such item does not exists in local storage, the value of ref will be undefined.
 * @param key The key of the item in local storage
 * @returns Item value wrapped inside a ref
 * @throws {StorageError} If called using the same key more than once
 */
export function getSyncedItem<T>(key: string): Ref<T | undefined> {
  checkItemLoaded(key);
  let value = JSON.parse(localStorage.getItem(key));
  value = value === null ? undefined : value;
  const wrapper: Ref = ref(value);

  watch(wrapper, (newValue) => {
    if (newValue === undefined) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(newValue));
    }
  },
  {
    deep: true,
  });

  return wrapper;
}

const keySet = new Set<string>();

function checkItemLoaded(key: string): void {
  if (keySet.has(key)) {
    throw new StorageError(`An item named ${key} is already loaded from local storage.`);
  }
  keySet.add(key);
}
