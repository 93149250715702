export type OneTeg = {
    isAvailableStartingElementForInsideClick: boolean
}

export function setValue(key, value) {
  if (Object.prototype.hasOwnProperty.call(window.OneTeg, key)) {
    window.OneTeg[key] = value;
  } else {
    console.error(`${key} is not a valid property of OneTeg`);
  }
}

export function getValue(key) {
  if (Object.prototype.hasOwnProperty.call(window.OneTeg, key)) {
    return window.OneTeg[key];
  }
  return console.error(`${key} is not a valid property of OneTeg`);
}

export function initializeValue() {
  window.OneTeg = {
    isAvailableStartingElementForInsideClick: false,
  };
}
